import React from "react";
import IconLogoPrestaShop from "../img/logos/logo-prestashop-icon.svg";
import LogoComExpertiseStore from "../img/logos/logo-comexpertise-store-bw.svg";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import LogoPrestashop from "../components/LogoPrestashop";
import Seo from "../components/Seo";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        siteName: name
        siteUrl
        siteLogo: image
        phone
        companyLogo
        twitterUrl
        facebookUrl
        youtubeUrl
        emailGeneral
        emailSupport
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const { t } = useTranslation();
  const { siteUrl, siteName, siteLogo, companyLogo, phone, emailSupport, emailGeneral, twitterUrl, facebookUrl, youtubeUrl } = data.site.siteMetadata;
  const seoSchemaJson = JSON.stringify({
    "@context": "https://schema.org/",
    "@graph": [
      {
        "@type": "WebSite",
        url: siteUrl,
        image: siteLogo,
        offers: {
          "@type": "Offer",
          category: t("ESN/E-business/E-commerce/Développeur"),
          eligibleCustomerType: ["http://purl.org/goodrelations/v1#Business", "http://purl.org/goodrelations/v1#PublicInstitution", "http://purl.org/goodrelations/v1#Reseller"],
          itemOffered: {
            "@type": "Service",
            audience: t("E-commerçants, e-marchants, TPE et PME"),
            serviceType: t("Support technique et maintenance de sites web (e-commerce)"),
            serviceOutput: [t("Développement de module PrestaShop"), t("Développement de module Drupal Commerce"), t("Développement de module Shopify"), t("Réparation de site web"), t("Optimisation de site web"), t("Audit et rapport technique de site web"), t("Hébergement de site web"), t("Maintenance boutique e-commerce"), t("Maintenance site web"), t("Expertise PrestaShop"), t("Développement API HTTP"), t("Développement passerelle CRM/ERP PrestaShop"), t("Développement passerelle CRM/ERP Drupal Commerce"), t("Développement passerelle CRM/ERP Shopify")],
            availableChannel: {
              "@type": "ServiceChannel",
              name: t("Support technique ComExpertise"),
              availableLanguage: [
                {
                  "@type": "Language",
                  name: t("Français"),
                  alternateName: "fr",
                },
                {
                  "@type": "Language",
                  name: t("Anglais"),
                  alternateName: "en",
                },
              ],
              servicePhone: {
                "@type": "ContactPoint",
                telephone: phone,
              },
              servicePostalAddress: {
                "@type": "PostalAddress",
                addressCountry: "FR",
                email: emailSupport,
              },
              serviceUrl: siteUrl,
            },
          },
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "Corporation",
        name: siteName,
        url: siteUrl,
        logo: companyLogo,
        sameAs: [facebookUrl, twitterUrl, youtubeUrl],
      },
    ],
  });

  return (
    <>
      <Seo title={t(`Modules PrestaShop ComExpertise & Assistance technique e-commerce`)} description={t(`Développement & maintenance de modules, thèmes et extensions pour les solutions e-commerce open-source PrestaShop, Drupal Commerce et Shopify, ComExpertise assure également l'assistance, la maintenance et le support technique de vos applications métier, CRM, ERP, intranet/extranet, et toutes solutions e-business développées en PHP et/ou JavaScript (Symfony/Laravel/NodeJS). ComExpertise est une Entreprise de Services du Numérique (ESN) localisée dans l'ouest de la France.`)} titleTemplate="%s">
        <script type="application/ld+json">{`${seoSchemaJson}`}</script>
        <body className="page-index" />
      </Seo>

      <div className="d-flex flex-column align-items-stretch justify-content-between">
        <header className="jumbotron jumbotron-fluid mb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xxl-5">
                <p className="mb-4">
                  <img className="comx-logo-revert img-fluid d-none d-md-block" height="205" width="430" src={LogoComExpertiseStore} alt={t("ComExpertise.store, ESN spécialisée PrestaShop")} />
                </p>
                <div className="comx-bg-primary-op p-4">
                  <p className="h1 display-4 text-uppercase text-light">
                    <Trans>#e-business</Trans>
                  </p>
                  <h1 className="lead text-light mb-4">
                    <Trans>Service 100% dédié à la maintenance et l'évolution de votre boutique PrestaShop &amp; solutions e-commerce open-source.</Trans>
                  </h1>

                  <p>
                    <LogoPrestashop height={30} alternative={"white"} useLink={false} />
                  </p>

                  <hr className="mb-5 w-50 ml-0 bg-dark" />

                  <h2 className="h5 text-success mb-3">
                    <i className="bi bi-life-preserver"></i> <Trans>Un problème sur votre boutique ?</Trans>
                  </h2>
                  <p className="text-success sr-only">
                    <Trans>Notre service d'assistance technique e-commerce composés de développeurs experts PrestaShop vous guident et peuvent intervenir sur votre boutique PrestaShop/Drupal Commerce/Shopify rapidement si besoin. Notre équipe de développeurs effectue tout type d'intervention technique.</Trans>
                  </p>

                  <p>
                    <u>
                      <a href={`mailto:${emailSupport}`} className="btn btn-outline-success" title={t("Contacter un développeur web, expert PrestaShop chez ComExpertise")}>
                        <Trans>Contactez un expert PrestaShop</Trans>
                      </a>
                    </u>
                  </p>

                  <h2 className="h5 text-info mt-5 mb-3">
                    <i className="bi bi-lightbulb-fill"></i> <Trans>Un projet, une idée ?</Trans>
                  </h2>
                  <p className="text-info sr-only">
                    <Trans>Vous souhaitez développer un module PrestaShop personnalisé, créer une passerelle avec votre CRM/ERP ? Un projet de migration vers PrestaShop ?</Trans>
                  </p>
                  <p>
                    <u>
                      <a href={`mailto:${emailGeneral}`} title={t("Contacter un expert PrestaShop chez ComExpertise pour un nouveau projet")} className="btn btn-outline-info">
                        <Trans>Parlez nous de votre projet</Trans>
                      </a>
                    </u>
                  </p>

                  <hr className="my-4 w-50 ml-0 bg-dark" />

                  <p className="lead mb-3">
                    <Trans>
                      Au service des e-commerçants depuis 2008, ComExpertise vous accompagne dans le <b>développement de modules PrestaShop</b>, la <b>création de thèmes e-commerce</b>, la <b>maintenance de sites e-commerce</b>, l'audit technique, l'hébergement de vos applicatifs mais aussi l'assistance à maîtrise d'ouvrage et les différentes stratégies (techniques) pour votre e-business.
                    </Trans>
                  </p>

                  <p>
                    <span className="badge badge-primary mr-1">#PrestaShop</span>
                    <span className="badge badge-primary mr-1">#Magento</span>
                    <span className="badge badge-primary mr-1">#Drupal Commerce</span>
                    <span className="badge badge-primary mr-1">#WooCommerce</span>
                    <span className="badge badge-primary">#Shopify</span>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-6 col-xxl-7"></div>
            </div>
          </div>
        </header>

        <div className="comx-products">
          <article className="comx-product-item container">
            <p className="mb-0" id="modules-prestashop">
              <LogoPrestashop height={30} />
            </p>
            <h2 className="comx-text-p" title={t("Modules PrestaShop développés par ComExpertise")}>
              <Trans>
                Nos <Link to="/modules/prestashop/">modules officiels pour PrestaShop</Link> :
              </Trans>
            </h2>
            <ul className="list-unstyled">
              <li>
                <Link className="btn btn-primary btn-lg text-left my-2" to="/modules/prestashop/facturation.pro-monae-matpe" role="button">
                  <img height="45" src={IconLogoPrestaShop} alt="PrestaShop" className="mr-3 float-left" /> Facturation.pro{" "}
                  <small className="d-block">
                    <Trans>(Connecteur pour MonAE/MaTPE)</Trans>
                  </small>
                </Link>
              </li>
              <li>
                <Link className="btn btn-primary btn-lg text-left my-2" to="/modules/prestashop/payments-access-restrictions" role="button">
                  <img height="45" src={IconLogoPrestaShop} alt="PrestaShop" className="mr-3 float-left" /> PaymentsAccess{" "}
                  <small className="d-block">
                    <Trans>(Restrictions des méthodes de paiements)</Trans>
                  </small>
                </Link>
              </li>
              <li>
                <Link
                  className="btn btn-primary btn-lg text-left my-2"
                  to="#"
                  onClick={(event) => {
                    event.preventDefault();
                    alert("Ce module sera bientôt disponible !");
                  }}
                  role="button"
                >
                  <img height="45" src={IconLogoPrestaShop} alt="PrestaShop" className="mr-3 float-left" /> Donation{" "}
                  <small className="d-block">
                    <Trans>(Donation sur vente à une oeuvre de votre choix)</Trans>
                  </small>
                </Link>
              </li>
            </ul>
          </article>
        </div>

        <p className="sr-only my-5 py-5 mx-3 d-none d-xl-block text-white display-1 comx-title-faded">
          <Trans>#E-commerce experts</Trans>
        </p>
      </div>
    </>
  );
};

export default IndexPage;
